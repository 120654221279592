  export const WorkWithUsBtn = () => {
    return (
      <>
        <a
          href="#"
          target="_blank"
          className="text-decoration-none"
        >
          <button class="WorkWithUsBtn btnText mx-1 mt-2">
            {" "}
            <b>Work With Us </b>
          </button>
        </a>
      </>
    );
  };
 